window.ChatApp = (() => {
  const state = {
    ifrm: {},
    isChatInitialised: false,
    environment: 'https://remotesupport.bestbuy.ca',
    iframeOrigin: 'https://bestbuycanada.secure.force.com',
    providedEntryTags: ['.covert-chat-entry'],
    queue: 'CODE',
    isChatExpanded: false,
  }
  const iframeResizeListener = event => {
    if (window.innerWidth < 800) {
      state.ifrm.contentWindow.postMessage(
        { screen: 'mobile' },
        state.environment
      )
      if (state.isChatExpanded && state.ifrm.classList.contains('expanded')) {
        state.ifrm.classList.remove('expanded')
      }
    } else {
      state.ifrm.contentWindow.postMessage(
        { screen: 'desktop' },
        state.environment
      )
      if (state.isChatExpanded && !state.ifrm.classList.contains('expanded')) {
        state.ifrm.classList.add('expanded')
      }
    }
  }
  const iframeMessageListener = msg => {
    switch (msg.data.type) {
      case 'isChatExpanded':
        if (msg.data.value) {
          if (window.innerWidth > 800) {
            state.ifrm.classList.add('expanded')
          }
          state.isChatExpanded = true
        } else {
          if (window.innerWidth > 800) {
            state.ifrm.classList.remove('expanded')
          }
          state.isChatExpanded = false
        }
        break
      case 'isChatInitialised':
        if (!msg.data.value) {
          state.ifr = document.querySelector('#covert-chat-app')
          setTimeout(() => {
            state.ifr.parentNode.removeChild(state.ifr)
            state.isChatInitialised = false
            window.removeEventListener('resize', iframeResizeListener)
            window.removeEventListener('message', iframeMessageListener)
          }, 2000)
        }
        break
      default:
    }
  }
  const loadChat = e => {
    e.preventDefault()
    if (!state.isChatInitialised) {
      const urlItems = window.location.pathname
        .split('/')
        .map(a => a.toLowerCase())
      const locale = urlItems.indexOf('fr-ca') !== -1 ? 'fr' : 'en'
      state.ifrm = document.createElement('iframe')
      state.ifrm.setAttribute('id', 'covert-chat-app')
      state.ifrm.setAttribute('frameborder', '0')
      state.ifrm.setAttribute(
        'src',
        `${state.environment}?locale=${locale}&queue=${state.queue}&screen=${
          window.innerWidth < 800 ? 'mobile' : 'desktop'
        }`
      )
      state.ifrm.setAttribute('scrolling', 'no')
      state.ifrm.classList.add('covert')
      document.body.appendChild(state.ifrm)
      window.addEventListener('resize', iframeResizeListener)
      window.addEventListener('message', iframeMessageListener)
      state.isChatInitialised = true
    }
  }
  const init = (elements = '') => {
    const style = document.createElement('style')
    style.textContent = `
      .covert {
        position: fixed;
        bottom: 0;
        z-index: 2000;
        right: 75px;
        width: 350px;
        height: 573px;
        transition: width 1s ease-out;
      }
      @media only screen and (max-width: 800px) {
        .covert {
          right: 0;
          width: 100%;
          height: 100%;
          }
      }
      .expanded {
        width: 700px;
      }`
    document.head.appendChild(style)
    if (Array.isArray(elements)) {
      state.providedEntryTags = state.providedEntryTags.concat(elements)
    } else if (typeof elements === 'string' && elements !== '') {
      state.providedEntryTags.push(elements)
    }
    document.addEventListener(
      'click',
      e => {
        if (!e.target.closest(state.providedEntryTags)) {
          return
        }
        e.preventDefault()
        e.stopPropagation()
        loadChat(e)
      },
      true
    )
  }
  return {
    init: elem => init(elem),
    state,
  }
})()
